$main-class: ".accordion";

#{$main-class} {
  // Prepare elements for animation.
  .js & {
    &__label {
      opacity: 0;
      transform: translateY(80px);
      transition: opacity calc($at / 2) $te, transform calc($at / 2) $te;
    }
  }

  // Animate the prepared elements.
  .js &.animated {
    #{$main-class}__label {
      opacity: 1;
      transform: none;
    }
  }

  // No animations when reduced motion is active
  @media (prefers-reduced-motion) {
    #{$main-class}__label {
      opacity: 1 !important;
      transform: none !important;
    }
  }
}
