$main-class: ".hero";

#{$main-class} {
  background-repeat: no-repeat;
  background-size: cover;
  line-height: px(40);
  line-height: rem(40);
  @apply text-neutral-lightest;
  @apply text-xl;
  position: relative;
  width: 100%;

  &__video-container {
    object-fit: cover;
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100% !important;
  }

  &__text-container {
    @apply mb-[9vh];
    @apply md:ml-xxl;
    @apply mt-[15vh] md:mt-[17vh];
  }

  &__logo {
    height: auto;
    margin-left: -10px;
    width: 300px;

    @media (min-width: theme("screens.sm")) {
      height: auto;
      width: 336px;
    }
  }

  &__title {
    @apply mt-sm md:mt-xl;
    @apply tracking-tighter;
    @apply font-regular;
    letter-spacing: -4, 8rem;
    font-size: 3rem;
    line-height: 3rem;

    @media (min-width: theme("screens.sm")) {
      @apply text-[88px];
      @apply leading-[102px];
      line-height: px(96);
      line-height: rem(96);
      max-width: 1100px;
    }
  }

  .container {
    height: 100%;
    align-items: center;
    display: flex;
    position: relative;
    z-index: 1;
  }

  // Prepare elements for animation.
  .js & {
    &__logo {
      opacity: 0;
      transition: opacity calc($at / 2) $te;
      transition-delay: calc($at / 2);
    }
    &__title {
      opacity: 0;
      transform: translateY(80px);
      transition: opacity calc($at / 2) $te, transform calc($at / 2) $te;
      transition-delay: $at;
    }
  }

  // Animate the prepared elements.
  .js &.animated {
    #{$main-class}__logo {
      opacity: 1;
    }
    #{$main-class}__title {
      opacity: 1;
      transform: none;
    }
  }

  // No animations when reduced motion is active
  @media (prefers-reduced-motion) {
    #{$main-class}__logo {
      opacity: 1 !important;
    }
    #{$main-class}__title {
      opacity: 1 !important;
      transform: none !important;
    }
  }
}
