.swiper-wrapper {
  display: flex;
}

.slider-navigation {
  margin-left: 0;
  margin-top: -1.5em;
  position: relative;
  text-align: right;

  .swiper-button-prev,
  .swiper-button-next {
    @apply bg-brand-primary-medium;
    background-position: center center;
    background-repeat: no-repeat;
    outline: none;
    padding: 0;
    border: 0;
    height: px($spacing-sm);
    line-height: 2.8;
    width: px($spacing-sm);
    display: inline-block;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
  }

  .swiper-button-prev {
    &:hover {
      transform: scale(1.1);
    }
  }

  .swiper-button-next {
    &:hover {
      transform: scale(1.1);
    }
  }

  .swiper-button-disabled {
    cursor: initial;
    opacity: 0.5;
  }
}

.swiper {
  &-container {
    margin-left: auto;
    margin-right: auto;
    position: relative;
    overflow: hidden;
    padding-left: 9%;
    /* Fix of Webkit flickering */
    z-index: 1;

    @media (min-width: $breakpoint-large - 1) {
      padding-left: em($spacing-md);
    }
  }

  &-wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    z-index: 1;
    display: flex;
    transition-property: transform;
    box-sizing: content-box;
  }

  &-slide {
    flex-shrink: 0;
    width: 100%;
    height: 100%;
    position: relative;
    transition-property: transform;

    .blog-card {
      padding: 0;
    }
  }

  &-button-pre {
    margin-right: em($spacing-quarck);
  }

  &__image {
    @supports (object-fit: cover) {
      object-fit: cover;
      object-position: top;

      @media (min-width: $breakpoint-large - 1) {
        min-width: 100%;
      }
    }
  }
}
