/* Utility classes
--------------------------------------------- */

@layer utilities {
  .u-responsive-line-break {
    display: block;

    @media (min-width: theme("screens.lg")) {
      display: none;
    }
  }

  .u-visually-hidden {
    position: absolute !important;
    clip: rect(1px 1px 1px 1px);
    /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0 !important;
    border: 0 !important;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
  }

  .u-align-left {
    text-align: left !important;
  }

  .u-center {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  .u-block-scroll {
    overflow: hidden;
  }

  .u-mobile-block-scroll {
    overflow: hidden;

    @media (min-width: theme("screens.lg")) {
      overflow: visible;
    }
  }

  .u-margin-top-desktop {
    display: block;

    @media (min-width: theme("screens.md")) {
      margin-top: 144px;
    }
  }

  .u-wysiwyg {
    p,
    a,
    img,
    ul,
    ol,
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      + p,
      + a,
      + img,
      + ul,
      + ol {
        @apply mt-sm md:mt-xxl;
      }

      + h1,
      + h2,
      + h3,
      + h4,
      + h5,
      + h6 {
        @apply mt-sm md:mt-xxl;
      }
    }
  }
}
