/* General
--------------------------------------------- */
@layer base {
  html {
    font-size: 100%;
    box-sizing: border-box;
    scroll-behavior: smooth;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  @media (prefers-reduced-motion: reduce) {
    *,
    *:before,
    *:after {
      animation: none !important;
      scroll-behavior: auto !important;
      transition: none !important;
    }
  }

  body {
    animation: fadeIn 0.5s;
    @apply font-base;
    margin: 0;
    min-width: 320px;
    background-color: black;
    -webkit-font-smoothing: antialiased;
  }

  img,
  svg {
    height: auto;
    max-width: 100%;
  }

  iframe {
    max-width: 100%;
  }

  a,
  button {
    text-decoration: none;

    img {
      vertical-align: top;
    }

    .is-desktop & {
      cursor: none;
    }
  }
}

@layer utilities {
  .container {
    overflow: hidden;
    max-width: 100% !important;
    @apply px-xxs #{!important};
    @apply py-md #{!important};

    @media (min-width: theme("screens.lg")) {
      @apply px-xxl #{!important};
      @apply py-xxl #{!important};
      max-width: 118rem !important;
    }

    .full-height {
      height: 100% !important;
    }
  }

  /* Removes some spacing when two sections have the same background color */
  .section--light + .section--light,
  .section--dark + .section--dark {
    .container {
      @apply pt-xxs;

      @media (min-width: theme("screens.xl")) {
        @apply pt-sm;
      }
    }
  }

  .barba-container {
    &.barba-container--fadein {
      animation: fadeIn 0.4s ease forwards;
    }

    &.barba-container--fadeout {
      animation: fadeOut 0.4s ease forwards;
    }

    .page-template-legal & {
      @apply bg-brand-secondary-darkest;
    }

    position: relative;
    background-clip: border-box;
    border-bottom-right-radius: 50px;
    border-bottom-left-radius: 50px;
    z-index: 1;
    overflow: hidden;

    @media (min-width: theme("screens.md")) {
      border-bottom-right-radius: 150px;
      border-bottom-left-radius: 150px;
    }
  }

  .col-group {
    max-width: 100%;
  }

  .js {
    .lazy {
      opacity: 0;

      &.loaded {
        animation: fadeIn 0.9s ease forwards;
        opacity: 1;
      }
    }
  }

  .wysiwyg {
    margin: 0 auto;
    max-width: 900px !important;
    @include body_regular;

    * + * {
      @include spacing-stack-xxxs;
    }

    h1,
    * + h2,
    h3,
    h4,
    h5,
    h6 {
      @apply text-lg;
      font-weight: bold;
      @include spacing-stack-lg;
      text-transform: uppercase;
    }

    h1 {
      @apply text-huge;
      margin-top: 0;
    }

    iframe {
      margin: inherit auto;
    }

    /* TODO: Remove importants after (old) imported the_content is reviewed and cleaned */

    p {
      font-weight: 300 !important;
    }

    b,
    strong {
      font-weight: 500 !important;
    }

    a {
      @apply text-neutral-darkest;
      text-decoration: underline;
      word-break: break-all;

      span {
        @apply text-neutral-darkest;
      }
    }

    strong {
      @apply text-neutral-darkest;

      a {
        @apply text-neutral-darkest;
      }
    }

    a[href*="\#"] {
      text-decoration: none;
    }

    h2,
    h3 {
      padding-bottom: px($spacing-xxxs);
      padding-bottom: rem($spacing-xxxs);
    }

    ol,
    ul {
      li {
        list-style: circle;
        margin-left: 2rem;
      }
    }

    iframe,
    .wp-video,
    img {
      max-width: 100%;
      display: block;
      margin-top: px($spacing-lg);
      margin-top: rem($spacing-lg);
    }

    img.alignleft,
    img.alignright {
      margin-top: 0;
    }

    img.alignleft {
      margin-right: px($spacing-sm);
      margin-right: rem($spacing-sm);
    }

    img.alignright {
      margin-left: px($spacing-sm);
      margin-left: rem($spacing-sm);
      margin-bottom: rem($spacing-xxs);
    }

    figure {
      max-width: 100%;
    }

    .wp-caption-text {
      @include overline_regular;
      padding: px($spacing-nano);
      padding: rem($spacing-nano);
    }

    td {
      border-bottom: solid 1px theme("colors.neutral.dark");
      padding: rem($spacing-nano);
    }
  }
}
