/*
 * Fluidable Grid System 1.4.1
 *
 * Creator: Andri Sigurðsson
 * Site: http://fluidable.com
 * Date: 14.11.2017
 * Converted to SASS by Rory Pickering - rorypickering.co.uk
 * Date converted: 8.11.2016
 */

/*
  Config
*/

// Grid

$columns: 12;
$gutterWidth: 36px;

// Column size

$columnWidth: calc(100% / $columns);

// Break-points

$screenTablet: theme("screens.sm");
$screenDesktop: theme("screens.md");
$screenLarge: theme("screens.lg");

/*
  Utils
*/

// Positioning

@mixin center-block {
  margin: 0 auto;
}

// Clearfix

@mixin clear {
  &:after {
    display: table;
    clear: both;
    content: " ";
  }
}

// stylelint-disable-next-line
@layer utilities {
  /*
    Mobile and up
  */

  [class*="col-fixed-"] {
    float: left;
    width: 100%;
  }

  .container {
    padding-right: calc($gutterWidth / 2);
    padding-left: calc($gutterWidth / 2);
  }

  .col-group {
    margin-right: calc(calc($gutterWidth / -2));
    margin-left: calc(calc($gutterWidth / -2));
    @include clear();
  }

  .col-group .col-group {
    padding: 0;
  }

  .col-group [class*="col-"] {
    min-height: 1px;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .col-group [class*="col-"]:not(.col-group):not(.col-content) {
    padding-right: calc($gutterWidth / 2);
    padding-left: calc($gutterWidth / 2);
  }

  .col-group [class*="push-"],
  .col-group [class*="pull-"] {
    position: relative;
  }

  @for $index from 1 through $columns {
    // Columns
    .col-mb-#{$index} {
      width: $columnWidth * $index;
      float: left;
      padding-right: calc($gutterWidth / 2);
      padding-left: calc($gutterWidth / 2);
    }
  }

  @for $index from 0 through $columns {
    // Offset
    .col-mb-offset-#{$index} {
      margin-left: $columnWidth * $index;
    }

    // Pull
    .col-mb-pull-#{$index} {
      right: $columnWidth * $index;
    }

    // Push
    .col-mb-push-#{$index} {
      left: $columnWidth * $index;
    }
  }

  /*
    Tablet and up
  */

  @media (min-width: $screenTablet) {
    .container {
      max-width: $screenTablet - ($gutterWidth * 2);
      @include center-block();
    }

    @for $index from 1 through $columns {
      // Columns

      .col-#{$index} {
        width: $columnWidth * $index;
        float: left;
        padding-right: calc($gutterWidth / 2);
        padding-left: calc($gutterWidth / 2);
      }
    }

    @for $index from 0 through $columns {
      // Offset

      .col-offset-#{$index} {
        margin-left: $columnWidth * $index;
      }

      // Pull

      .col-pull-#{$index} {
        right: $columnWidth * $index;
      }

      // Push

      .col-push-#{$index} {
        left: $columnWidth * $index;
      }
    }

    // Groups

    .col-group {
      margin-right: calc($gutterWidth / -2);
      margin-left: calc($gutterWidth / -2);
      padding: 0;
      @include clear();
    }
  }

  /*
    Desktop and up
  */

  @media (min-width: $screenDesktop) {
    .container {
      max-width: $screenDesktop - ($gutterWidth * 2);
      @include center-block();
    }

    @for $index from 1 through $columns {
      // Columns

      .col-dt-#{$index} {
        width: $columnWidth * $index;
        float: left;
        padding-right: calc($gutterWidth / 2);
        padding-left: calc($gutterWidth / 2);
      }
    }

    @for $index from 0 through $columns {
      // Offset

      .col-dt-offset-#{$index} {
        margin-left: $columnWidth * $index;
      }

      // Pull

      .col-dt-pull-#{$index} {
        right: $columnWidth * $index;
      }

      // Push
      .col-dt-push-#{$index} {
        left: $columnWidth * $index;
      }
    }
  }

  /*
    Large desktop and up
  */

  @media (min-width: $screenLarge) {
    .container {
      max-width: $screenLarge - ($gutterWidth * 2);
      @include center-block();
    }

    @for $index from 1 through $columns {
      // Columns

      .col-ld-#{$index} {
        width: $columnWidth * $index;
        float: left;
        padding-right: calc($gutterWidth / 2);
        padding-left: calc($gutterWidth / 2);
      }
    }

    @for $index from 0 through $columns {
      // Offset

      .col-ld-offset-#{$index} {
        margin-left: $columnWidth * $index;
      }

      // Pull

      .col-ld-pull-#{$index} {
        right: $columnWidth * $index;
      }

      // Push
      .col-ld-push-#{$index} {
        left: $columnWidth * $index;
      }
    }
  }

  /*
    Fixed aspect ratio columns
  */

  .col-fixed-hd,
  .col-fixed-landscape,
  .col-fixed-square,
  .col-fixed-portrait {
    position: relative;
  }

  .col-fixed-hd:before,
  .col-fixed-landscape:before,
  .col-fixed-square:before,
  .col-fixed-portrait:before {
    content: "";
    display: block;
  }

  .col-fixed-hd .col-content,
  .col-fixed-landscape .col-content,
  .col-fixed-square .col-content,
  .col-fixed-portrait .col-content {
    position: absolute;
    top: 0;
    right: calc($gutterWidth / 2);
    bottom: 0;
    left: calc($gutterWidth / 2);
    padding: 0;
  }

  .col-fixed-hd:before {
    margin-top: 56.25%;
  }

  .col-fixed-landscape:before {
    margin-top: 75%;
  }

  .col-fixed-square:before {
    margin-top: 100%;
  }

  .col-fixed-portrait:before {
    margin-top: 133.33333333%;
  }
}
