/*!
Theme Name: Byteberry
Author: Byteberry Studio
Author URI: https://byteberry.studio/
Text Domain: theme
*/

// Global
@import "globals/variables";
@import "globals/functions";
@import "globals/buttons";
@import "globals/typography";
@import "globals/spacing";
@import "globals/decoration";
@import "globals/animations";
@import "globals/mixins";
@import "globals/general";
@import "globals/utility";
@import "globals/grid";
@import "globals/wp";

// Base
@tailwind base;

// Components
@tailwind components;

// Template parts - Components
@import "template-parts/components/slider.scss";

// Template parts - Common
@import "template-parts/common/header.scss";
@import "template-parts/common/footer.scss";

// Template parts - Popups
@import "template-parts/popups/*.scss";

// Template parts - Sections
@import "template-parts/sections/accordion.scss";
@import "template-parts/sections/hero.scss";
@import "template-parts/sections/slider-gallery.scss";
@import "template-parts/sections/text.scss";

// Templates
@import "templates/**/*.scss";

// Utilities
@tailwind utilities;
