/* Footer
--------------------------------------------- */

.footer {
  @apply bg-neutral-darkest;
  @apply text-neutral-lightest;
  @apply w-auto;
  @apply sticky;
  @apply bottom-0;
  @apply flex;
  @apply flex-col;
  @apply items-center;

  &__contact {
    @apply text-center sm:text-left;
  }

  &__email {
    @apply my-2;
    @apply mt-nano;
  }

  &__say,
  &__email-link {
    @apply text-brand-primary-medium;
    @apply text-lg sm:text-giant md:text-hero;
    @apply font-bold;
    @apply pr-2;
    @apply inline-block;
  }

  &__madein {
    @apply text-tiny;
    @apply mt-nano;
    @apply mb-2.5;
  }

  &__getintouch {
    @apply text-neutral-dark;
    @apply font-medium;
    @apply text-lg;

    @media (min-width: theme("screens.md")) {
      @apply text-2xl;
    }
  }

  &__legal {
    text-align: center;
    width: 100%;
  }

  &__copyright {
    display: inline-block;
    @apply text-tiny md:text-xs;
  }

  &__legal-menu {
    display: inline-block;
    @apply mb-xs;
    @apply md:mt-xl;

    li {
      display: inline-block;
      @apply ml-nano;
      @apply mr-nano;

      a {
        @apply text-tiny md:text-xs;
        text-decoration: underline;
      }
    }
  }

  .container {
    @apply pl-0 md:pl-xxxl #{!important};
    @apply pr-0 md:pr-xxxl #{!important};
    @apply pt-20 md:pt-xxl;
  }

  .col-1 {
    @apply mt-sm md:mt-0;
  }
}

.cursor-wrapper {
  align-items: center;
  bottom: 0%;
  display: flex;
  justify-content: center;
  left: 0%;
  mix-blend-mode: difference;
  pointer-events: none;
  position: fixed;
  right: 0%;
  top: 0%;
  z-index: 999999;
}

.cursor {
  align-items: center;
  border: 3px solid #5900d3;
  border-radius: 100%;
  display: flex;
  height: 40px;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  transform-style: preserve-3d;
  transition: height $tt $te, width $tt $te, margin $tt $te;
  width: 40px;
  will-change: transform;

  div {
    display: none;
  }

  &.expand {
    height: 60px;
    margin-left: -10px;
    margin-top: -10px;
    width: 60px;
  }

  &.slide {
    @apply bg-brand-primary-dark;
    @apply text-neutral-lightest;
    height: 100px;
    margin-left: -30px;
    margin-top: -30px;
    width: 100px;

    .cursor-drag {
      display: initial;
    }
  }

  &.click {
    .cursor-drag {
      display: none;
    }
    .cursor-click {
      display: initial;
    }
  }
}
