/* 404 template
--------------------------------------------- */

$main-class: ".not-found";

#{$main-class} {
  @apply bg-brand-primary-dark;
  @apply text-neutral-lightest;
  min-height: 50vh;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }

  &__heading {
    font-size: 100px;
    font-size: rem(100);
    letter-spacing: 8.5px;
    margin: 0;

    @media (min-width: theme("screens.md")) {
      font-size: 150px;
      font-size: rem(150);
    }

    @media (min-width: theme("screens.lg")) {
      font-size: 200px;
      font-size: rem(200);
    }
  }

  &__subheading {
    font-size: 21px;
    font-size: rem(21);
    line-height: 60px;
    margin: 0 0 50px;

    @media (min-width: theme("screens.md")) {
      margin-bottom: 80px;
    }

    @media (min-width: theme("screens.lg")) {
      font-size: 42px;
      font-size: rem(42);
      margin-bottom: 100px;
    }
  }
}

/* Front page template
--------------------------------------------- */

/* Legal template
--------------------------------------------- */

$main-class: ".page-template-legal";

#{$main-class} {
  .container {
    @apply mb-xxl mt-xxl;
  }
}
