/* Typography
--------------------------------------------- */

@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 400;
  src: url(#{$theme-path}/fonts/IbmFontReg.woff2) format("woff2");
  font-display: swap;
}

@font-face {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 700;
  src: url(#{$theme-path}/fonts/IbmFontBold.woff2) format("woff2");
  font-display: swap;
}

/* 01. Display */
@mixin display_regular {
  @apply font-highlight font-normal leading-base text-display;
}

@mixin display_bold {
  @apply display_regular font-bold;
}

/* 02. Headline */
@mixin headline_regular {
  @apply font-highlight font-normal leading-base text-giant;
}

@mixin headline_bold {
  @apply headline_regular font-bold;
}

/* 03. Title */
@mixin title_regular {
  @apply font-highlight font-normal leading-base text-huge;
}

@mixin title_bold {
  @apply title_regular font-bold;
}

@mixin title_biggest {
  @apply title_regular font-biggest;
}

@mixin title_small_regular {
  @apply title_regular font-normal leading-short text-xl;
}

@mixin title_small_medium {
  @apply title_small_regular font-medium;
}

@mixin title_small_regular_underline {
  @apply title_small_medium font-normal underline;
}

/* 04. Subtitle */
@mixin subtitle_regular {
  @apply font-highlight font-normal leading-short text-lg;
  @media (max-width: $breakpoint-large - 1) {
    @apply text-sm;
  }
}

@mixin subtitle_medium {
  @apply subtitle_regular font-medium;
}

/* 05. Body */
@mixin body_regular {
  @apply font-base font-normal leading-long text-sm;
  @media (max-width: $breakpoint-large - 1) {
    @apply text-nano;
  }
}

@mixin body_bold {
  @apply body_regular font-bold;
}

@mixin body_small_regular {
  @apply body_regular font-normal text-xs;
}

@mixin body_small_bold {
  @apply body_small_regular font-bold;
}

/* 06. Overline */
@mixin overline_regular {
  @apply font-base font-normal leading-medium text-nano;
}

@mixin overline_bold {
  @apply overline_regular font-bold;
}

@layer utilities {
  .display_regular {
    @include display_regular;
  }

  .display_bold {
    @include display_bold;
  }

  .headline_regular {
    @include headline_regular;
  }

  .headline_bold {
    @include headline_bold;
  }

  .title_regular {
    @include title_regular;
  }

  .title_bold {
    @include title_bold;
  }

  .title_biggest {
    @include title_biggest;
  }

  .title_small_regular {
    @include title_small_regular;
  }

  .title_small_medium {
    @include title_small_medium;
  }

  .title_small_regular_underline {
    @include title_small_regular_underline;
  }

  .subtitle_regular {
    @include subtitle_regular;
  }

  .subtitle_medium {
    @include subtitle_medium;
  }

  .body_regular {
    @include body_regular;
  }

  .body_bold {
    @include body_bold;
  }

  .body_small_regular {
    @include body_small_regular;
  }

  .body_small_bold {
    @include body_small_bold;
  }

  /* 06. Overline */
  .overline_regular {
    @include overline_regular;
  }

  .overline_bold {
    @include overline_bold;
  }
}
