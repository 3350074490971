/* Animations
--------------------------------------------- */

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes validating {
  70% {
    background: rgba(181, 126, 16, 0.2);
  }

  100% {
    background: rgba(181, 126, 16, 0.1);
  }
}

@keyframes dash {
  from {
    stroke-dashoffset: 60;
  }

  to {
    stroke-dashoffset: 0;
  }
}
