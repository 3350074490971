/* Header
--------------------------------------------- */

$main-class: ".header";

#{$main-class} {
  position: fixed;
  transition: transform $tt ease-out;
  width: 100%;
  z-index: 2;
  padding-left: 35px;
  padding-right: 35px;
  padding-top: 15px;

  &__logo {
    display: table;
    float: left;
    @include spacing-inline-xs;
    opacity: 0;
    width: 120px;

    &--animated {
      animation: fadeIn 0.4s ease forwards;
    }

    a {
      display: table-cell;
      vertical-align: middle;
    }

    @media (min-width: theme("screens.md")) {
      height: 57px;
    }

    .page-template-legal &,
    .error404 & {
      opacity: 1;
    }

    .invert-colors &,
    .page-template-legal & {
      svg {
        path {
          @apply fill-neutral-darkest;
        }
      }
    }
  }

  &__menu {
    @media (max-width: theme("screens.md")) {
      display: table;
      height: 100%;
      width: 100%;
    }

    @media (min-width: theme("screens.md")) {
      display: block;
      float: right;
      @apply text-sm;
      @apply font-regular;
    }

    &-container {
      &__list {
        display: table-cell;
        vertical-align: middle;

        @media (min-width: theme("screens.md")) {
          display: inline;
        }
      }
    }

    ul {
      ul {
        //second nav
        display: none;

        li {
          float: none;
        }
      }
    }

    li {
      display: inline-block;
      list-style: none;
      @include spacing-stack-nano;
      @include spacing-inline-nano;
      @include spacing-squish-nano;

      @for $i from 1 through 8 {
        &:nth-child(#{$i}) {
          transition-delay: $tt + (0.05s * ($i - 1));
        }
      }

      @media (max-width: $breakpoint-large - 1) {
        display: block;
        @apply text-xl;
        opacity: 0;
        text-align: center;
        transform: translateY(-15px);
        transition: opacity $tt $te, transform $tt $te;
        width: 100%;
      }

      a {
        @apply border-b border-transparent;
        @apply text-neutral-lightest;
        text-decoration: none;
        display: inline-block;

        &:link,
        &:visited {
          @apply text-neutral-lightest;

          .invert-colors &,
          .page-template-legal & {
            @media (min-width: $breakpoint-large) {
              @apply text-neutral-darkest;
            }
          }

          &:hover {
            @apply border-neutral-lightest;

            .invert-colors &,
            .page-template-legal & {
              @media (min-width: $breakpoint-large) {
                @apply border-neutral-darkest;
                @apply text-neutral-darkest;
              }
            }
          }
        }
      }

      &:hover ul {
        display: block;
        position: absolute;
      }
    }

    @media (max-width: $breakpoint-large - 1) {
      &-container {
        @apply bg-brand-primary-darkest;
        height: 100vh;
        height: var(--vh);
        overflow: hidden;
        overflow-y: scroll;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateY(-100%);
        transition: transform $tt $te;
        width: 100%;
      }

      li {
        float: right;

        &:hover ul {
          display: block;
          position: relative;
        }
      }
    }
  }

  .u-mobile-block-scroll & {
    bottom: 100%;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }

  /*   &__menu-container__list {
    margin-top: px(100);
    margin-top: rem(100);
  }
 */

  // Language selection toggler.
  /*  #languages-toggler {
    display: none;

    &:checked ~ #{$main-class}__languages {
      @media (min-width: theme("screens.lg")) {
        transition: background-color $tt $te;
        @apply bg-neutral-light;

        #{$main-class}__languages__toggler {
          svg {
            path {
              @apply fill-neutral-lightest;
            }
          }
        }

        ul {
          top: 39px;
          left: auto;
          right: 0;
          @apply bg-neutral-light;
        }

        li {
          opacity: 1;
          transform: none;
        }
      }
    }
  }

  // Languages list.
  &__languages {
    position: relative;

    @media (max-width: $breakpoint-large - 1) {
      bottom: 0;
      left: 0;
      position: fixed;
      width: 100%;
    }

    @media (min-width: theme("screens.lg")) {
      border-radius: 26px 26px 0 0;
      float: right;
      padding: 13px 10px;
    }

    // The languages selector toggler. Used only on large resolutions.
    &__toggler {
      // Hide the languages toggler for small resolutions.
      display: none;
      cursor: pointer;

      // Show the languages toggler for large resolutions.
      @media (min-width: theme("screens.lg")) {
        display: inline-block;
        -webkit-tap-highlight-color: transparent;
      }

      &:hover svg path {
        @apply fill-brand-primary-dark;
      }

      svg {
        height: 26px;
        line-height: 26px;

        path {
          transition: fill $tt $te;
        }
      }
    }

    ul {
      list-style: none;
      width: 100%;
      margin: 0;
      padding: 0 30px 45px 30px;
      text-align: center;
      transition: background-color $tt $te;

      @media (min-width: theme("screens.lg")) {
        position: absolute;
        top: -9999em;
        left: -9999em;
        border-radius: 0 0 26px 26px;
        padding: 13px 0;
      }
    }

    li {
      margin: 0;
      padding: 0;
      opacity: 0;
      transform: translateY(15px);
      transition: opacity $tt $te, transform $tt $te;

      @media (min-width: theme("screens.lg")) {
        transform: translateY(-15px);
      }

      &.current-lang a {
        @apply text-brand-primary-medium;
        font-weight: bold;

        @media (min-width: theme("screens.lg")) {
          @apply text-neutral-lightest;
        }

        &:hover {
          @apply text-brand-primary-lightest;
        }
      }

      a {
        display: block;
        padding: 0.5em 15px;
        @apply text-brand-primary-darkest;
        font-size: 0.625rem; // 10px
        text-transform: uppercase;

        @media (min-width: theme("screens.lg")) {
          padding: 0.5em 0;
        }

        &:hover {
          @apply text-brand-primary-lightest;
        }
      }
    }
  }

  &:after {
    clear: both;
    content: ".";
    display: block;
    height: 0;
    visibility: hidden;
  } */
}

// The menu toggler checkbox, for small resolutions.
#main-menu-toggler {
  display: none;

  // When the menu toggler is active.
  &:checked ~ #{$main-class} {
    position: sticky;
    transform: translateZ(0) !important;

    #{$main-class}__menu__toggler {
      &:before {
        @apply bg-neutral-lightest #{!important};
        transform: translateY(6px) rotate(-45deg);
      }

      .hamburguer {
        transform: scaleX(0);
      }

      &:after {
        @apply bg-neutral-lightest #{!important};
        transform: translateY(-6px) rotate(45deg);
      }
    }

    #{$main-class}__menu-container {
      top: 0;
      left: 0;
      transform: scaleY(1);
      transition-delay: 0s;

      @media (max-width: $breakpoint-large - 1) {
        &:before {
          transform: scale(1);
        }

        li {
          opacity: 1;
          transform: none;
        }
      }
    }
  }
}

// The menu toggler label.
#{$main-class}__menu__toggler {
  cursor: pointer;
  position: relative;
  z-index: 3;
  display: block;
  width: 34px;
  height: 32px;
  margin-left: auto;
  padding: 0;
  -webkit-tap-highlight-color: transparent;

  // Hide the menu toggler for large resolutions.
  @media (min-width: theme("screens.md")) {
    display: none;
  }

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      .hamburguer,
      &:before,
      &:after {
        @apply bg-brand-primary-lightest;
      }
    }
  }

  .hamburguer,
  &:before,
  &:after {
    position: absolute;
    display: block;
    top: 16px;
    right: 0;
    width: 24px;
    height: 1px;
    @apply bg-neutral-lightest;
    transition: background-color $tt $te, transform $tt $te;
    will-change: background-color, transform;

    .invert-colors &,
    .page-template-legal & {
      @apply bg-neutral-darkest;
    }
  }

  &:before,
  &:after {
    content: "";
    top: 10px;
  }

  &:after {
    top: 22px;
  }
}
