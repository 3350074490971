/* Cookies banner
--------------------------------------------- */

.cookies-banner {
  bottom: 0;
  @apply text-neutral-lightest;
  left: 0;
  max-height: 100%;
  opacity: 0;
  position: fixed;
  transform: translate(0, 100%);
  width: 100%;
  z-index: 9999;

  .container {
    @apply bg-brand-secondary-dark;
    margin-bottom: 20px;
    margin-left: auto;
    margin-right: auto;
    overflow-y: scroll;
    padding: 3rem 2rem !important;
    position: relative;
    width: 90%;
    z-index: 1;
  }

  a {
    @apply text-neutral-lightest;
    text-decoration: underline;

    &:hover {
      @apply text-brand-primary-dark;
    }
  }

  &__details {
    height: 100%;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 99999;

    &--all {
      @include spacing-stack-md;
    }

    .cookies-banner__close {
      right: px($spacing-xxxs);
      top: rem($spacing-xxxs);
      transform: none;
    }
  }

  &__background {
    background: rgba(31, 31, 31, 0.9);
    height: 100%;
    width: 100%;
  }

  &__modal {
    @apply bg-neutral-lightest;
    left: 50%;
    @include spacing-stretch-xs;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);

    @media (max-width: theme("screens.xs")) {
      height: 90%;
      overflow: scroll;
      width: 90%;
    }
  }

  &__title {
    font-family: "Barlow", sans-serif;
    letter-spacing: 2px;
    margin-top: 0;
  }

  &__text,
  &__actions {
    display: block;
    float: left;
  }

  &__text {
    @media (min-width: theme("screens.xl")) {
      max-width: 60%;
    }
  }

  &__actions {
    @include spacing-stack-xxxs;

    @media (max-width: theme("screens.xs")) {
      width: 100%;
    }

    @media (min-width: theme("screens.xs")) {
      @include spacing-stack-xxs;
      float: right;
    }

    @media (min-width: theme("screens.xl")) {
      @include spacing-stack-xxxs;
    }
  }

  &__close {
    background: transparent;
    @apply border-none;
    position: fixed;
    right: px($spacing-xxxs);
    top: rem($spacing-xxxs);
    -webkit-appearance: none;

    &:hover {
      svg path {
        @apply fill-brand-primary-dark;
      }
    }

    &--dark {
      svg path {
        @apply fill-brand-primary-darkest;
      }
    }

    svg {
      width: px($spacing-xxxs);
      height: rem($spacing-xxxs);

      path {
        transition: fill $tt $te;
      }
    }
  }

  &__item {
    @include spacing-stack-xs;
    overflow: hidden;

    &__label {
      display: block;
      float: left;
      @include spacing-stack-quarck;
      max-width: calc(100% - 80px);
    }

    &__choices {
      display: block;
      float: right;
    }

    &__switch {
      display: inline-block;
      height: px($spacing-xs);
      position: relative;
      width: px($spacing-lg);

      input {
        height: 0;
        opacity: 0;
        width: 0;

        &:checked + .switch__slider {
          @apply bg-brand-primary-darkest;
        }

        &:checked + .switch__slider:before {
          transform: translateX(px($spacing-xxs));
        }
      }

      .switch__slider {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        @apply bg-brand-primary-dark;
        transition: $tt;

        &:before {
          position: absolute;
          content: "";
          height: px($spacing-xxs);
          width: px($spacing-xxs);
          left: px($spacing-quarck);
          bottom: px($spacing-quarck);
          @apply bg-neutral-lightest;
          transition: $tt;
        }
      }
    }
  }

  &__save {
    @include spacing-stack-md;
    text-align: center;
  }

  &__deny {
    display: none;
  }

  .reject,
  .details,
  .accept,
  &__save .save {
    background: transparent;
    @apply border-thin;
    @apply border-neutral-lightest;
    @apply text-neutral-lightest;
    @include spacing-inline-xxxs;
    @include spacing-squish-xxxs;
    transition: all $tt $te, border-color $tt $te;
  }

  .reject,
  .details,
  .accept {
    @media (max-width: theme("screens.xs")) {
      width: 100%;
    }

    @media (max-width: theme("screens.xs")) {
      @include spacing-stack-xxxs;
    }
  }

  .reject,
  .details,
  .accept {
    @media (min-width: theme("screens.xs")) {
      float: right;
    }
  }

  .reject {
    @apply border-none;
    padding-bottom: 0;
  }

  .details {
    @include spacing-inline-xs;
  }

  .accept {
    @apply bg-neutral-lightest;
    @apply text-brand-primary-darkest;
    margin-right: 0;
  }

  &__save .save {
    @apply bg-brand-primary-darkest;
    @apply border-none;
    @apply text-neutral-lightest;
    @include spacing-squish-xxxs;
  }
}

.modal {
  &__title {
    @apply border-b;
    @apply border-solid;
    @apply border-brand-primary-dark;
    font-family: "Barlow", sans-serif;
    letter-spacing: 0;
    margin-top: 0;
    padding-bottom: 0.3em;
  }

  &__text {
    p {
      &:nth-child(2) {
        @include spacing-stack-xs;
      }
    }
  }
}
