/* Variables
--------------------------------------------- */

// Theme path
$theme-path: "/wp-content/themes/byteberry";

// Breakpoint variables (mobile-portrait by default)
$breakpoint-small: 640px; // Mobile-landscape (and larger)
$breakpoint-medium: 768px; // Tablet-portrait (and larger)
$breakpoint-large: 1024px; // Tablet-landscape (and larger)
$breakpoint-extra-large: 1280px; // Laptops (and langer)

// container
$container-max-width: theme("screens.xl");

// Transitions.
$tt: 0.3s;
$te: ease-in-out;
$at: 1s;
$ae: cubic-bezier(0.46, 0.01, 0.41, 1.38);
$ad: 0.3s;
