/* Spacing
--------------------------------------------- */

$spacing-quarck: 4;
$spacing-nano: 8;
$spacing-xxxs: 16;
$spacing-xxs: 24;
$spacing-xs: 32;
$spacing-sm: 40;
$spacing-md: 48;
$spacing-lg: 56;
$spacing-xl: 64;
$spacing-xxl: 80;
$spacing-xxxl: 120;
$spacing-huge: 160;
$spacing-giant: 200;

/* Spacing stack
--------------------------------------------- */

@mixin spacing-stack-quarck {
  margin-top: px($spacing-quarck);
  margin-top: rem($spacing-quarck);
}

@mixin spacing-stack-nano {
  margin-top: px($spacing-nano);
  margin-top: rem($spacing-nano);
}

@mixin spacing-stack-xxxs {
  margin-top: px($spacing-xxxs);
  margin-top: rem($spacing-xxxs);
}

@mixin spacing-stack-xxs {
  margin-top: px($spacing-xxs);
  margin-top: rem($spacing-xxs);
}

@mixin spacing-stack-xs {
  margin-top: px($spacing-xs);
  margin-top: rem($spacing-xs);
}

@mixin spacing-stack-sm {
  margin-top: px($spacing-sm);
  margin-top: rem($spacing-sm);
}

@mixin spacing-stack-md {
  margin-top: px($spacing-md);
  margin-top: rem($spacing-md);
}

@mixin spacing-stack-lg {
  margin-top: px($spacing-lg);
  margin-top: rem($spacing-lg);
}

@mixin spacing-stack-xl {
  margin-top: px($spacing-xl);
  margin-top: rem($spacing-xl);
}

@mixin spacing-stack-xxl {
  margin-top: px($spacing-xxl);
  margin-top: rem($spacing-xxl);
}

@mixin spacing-stack-xxxl {
  margin-top: px($spacing-xxxl);
  margin-top: rem($spacing-xxxl);
}

@mixin spacing-stack-huge {
  margin-top: px($spacing-huge);
  margin-top: rem($spacing-huge);
}

@mixin spacing-stack-giant {
  margin-top: px($spacing-giant);
  margin-top: rem($spacing-giant);
}

/* Spacing inline
--------------------------------------------- */

@mixin spacing-inline-quarck {
  margin-right: px($spacing-quarck);
  margin-right: rem($spacing-quarck);
}

@mixin spacing-inline-nano {
  margin-right: px($spacing-nano);
  margin-right: rem($spacing-nano);
}

@mixin spacing-inline-xxxs {
  margin-right: px($spacing-xxxs);
  margin-right: rem($spacing-xxxs);
}

@mixin spacing-inline-xxs {
  margin-right: px($spacing-xxs);
  margin-right: rem($spacing-xxs);
}

@mixin spacing-inline-xs {
  margin-right: px($spacing-xs);
  margin-right: rem($spacing-xs);
}

@mixin spacing-inline-sm {
  margin-right: px($spacing-sm);
  margin-right: rem($spacing-sm);
}

@mixin spacing-inline-md {
  margin-right: px($spacing-md);
  margin-right: rem($spacing-md);
}

@mixin spacing-inline-lg {
  margin-right: px($spacing-lg);
  margin-right: rem($spacing-lg);
}

@mixin spacing-inline-xl {
  margin-right: px($spacing-xl);
  margin-right: rem($spacing-xl);
}

@mixin spacing-inline-xxl {
  margin-right: px($spacing-xxl);
  margin-right: rem($spacing-xxl);
}

@mixin spacing-inline-xxxl {
  margin-right: px($spacing-xxxl);
  margin-right: rem($spacing-xxxl);
}

@mixin spacing-inline-huge {
  margin-right: px($spacing-huge);
  margin-right: rem($spacing-huge);
}

@mixin spacing-inline-giant {
  margin-right: px($spacing-giant);
  margin-right: rem($spacing-giant);
}

/* Spacing inset
--------------------------------------------- */

@mixin spacing-inset-quarck {
  padding: px($spacing-quarck);
  padding: rem($spacing-quarck);
}

@mixin spacing-inset-nano {
  padding: px($spacing-nano);
  padding: rem($spacing-nano);
}

@mixin spacing-inset-xxxs {
  padding: px($spacing-xxxs);
  padding: rem($spacing-xxxs);
}

@mixin spacing-inset-xxs {
  padding: px($spacing-xxs);
  padding: rem($spacing-xxs);
}

@mixin spacing-inset-xs {
  padding: px($spacing-xs);
  padding: rem($spacing-xs);
}

@mixin spacing-inset-sm {
  padding: px($spacing-sm);
  padding: rem($spacing-sm);
}

@mixin spacing-inset-md {
  padding: px($spacing-md);
  padding: rem($spacing-md);
}

/* Spacing Squish
--------------------------------------------- */

@mixin spacing-squish-quarck {
  padding: px($spacing-quarck) px($spacing-nano);
  padding: rem($spacing-quarck) rem($spacing-nano);
}

@mixin spacing-squish-nano {
  padding: px($spacing-nano) px($spacing-xxxs);
  padding: rem($spacing-nano) rem($spacing-xxxs);
}

@mixin spacing-squish-xxxs {
  padding: px($spacing-xxxs) px($spacing-xxs);
  padding: rem($spacing-xxxs) rem($spacing-xxs);
}

@mixin spacing-squish-xxs {
  padding: px($spacing-xxs) px($spacing-xs);
  padding: rem($spacing-xxs) rem($spacing-xs);
}

@mixin spacing-squish-md {
  padding: px($spacing-xs) px($spacing-sm);
  padding: rem($spacing-xs) rem($spacing-sm);
}

@mixin spacing-squish-sm {
  padding: px($spacing-sm) px($spacing-md);
  padding: rem($spacing-sm) rem($spacing-md);
}

@mixin spacing-squish-lg {
  padding: px($spacing-md) px($spacing-lg);
  padding: rem($spacing-md) rem($spacing-lg);
}

/* Spacing Stretch
--------------------------------------------- */

@mixin spacing-stretch-quarck {
  padding: px($spacing-nano) px($spacing-quarck);
  padding: rem($spacing-nano) rem($spacing-quarck);
}

@mixin spacing-stretch-nano {
  padding: px($spacing-xxxs) px($spacing-nano);
  padding: rem($spacing-xxxs) rem($spacing-nano);
}

@mixin spacing-stretch-xxxs {
  padding: px($spacing-xxs) px($spacing-xxxs);
  padding: rem($spacing-xxs) rem($spacing-xxxs);
}

@mixin spacing-stretch-xxs {
  padding: px($spacing-xs) px($spacing-xxs);
  padding: rem($spacing-xs) rem($spacing-xxs);
}

@mixin spacing-stretch-xs {
  padding: px($spacing-sm) px($spacing-xs);
  padding: rem($spacing-sm) rem($spacing-xs);
}

@mixin spacing-stretch-sm {
  padding: px($spacing-md) px($spacing-sm);
  padding: rem($spacing-md) rem($spacing-sm);
}

@mixin spacing-stretch-md {
  padding: px($spacing-lg) px($spacing-md);
  padding: rem($spacing-lg) rem($spacing-md);
}

@mixin spacing-stretch-lg {
  padding: px($spacing-xl) px($spacing-md);
  padding: rem($spacing-xl) rem($spacing-md);
}
