$main-class: ".text";

#{$main-class} {
  @apply bg-neutral-darkest;
  @apply text-neutral-lightest;
  @apply text-sm md:text-lg xxl:text-xl;

  &__paragraph--left {
    @apply md:pr-sm;
  }

  h2 {
    @apply text-xl md:text-huge xxl:text-giant;
    @apply font-bold;
    @apply leading-none;
  }

  &__paragraph--one-column {
    @apply text-neutral-dark;
    @apply font-normal;

    h2 {
      @apply text-5xl md:text-ultrahero xl:text-gigahero;
    }
  }

  .col-dt-6 {
    padding-right: 0 !important;
  }

  .col-dt-6 + .col-dt-6 {
    @apply mt-sm md:mt-0;
  }

  // Prepare elements for animation.
  .js & {
    &__paragraph {
      opacity: 0;
      transform: translateY(80px);
      transition: opacity calc($at / 2) $te, transform calc($at / 2) $te;
    }
  }

  // Animate the prepared elements.
  .js &.animated {
    #{$main-class}__paragraph {
      opacity: 1;
      transform: none;
    }
  }

  // No animations when reduced motion is active
  @media (prefers-reduced-motion) {
    #{$main-class}__paragraph {
      opacity: 1 !important;
      transform: none !important;
    }
  }
}
