$main-class: ".slider-gallery";

#{$main-class} {
  &__button {
    transition: opacity calc($at / 2) $te;
  }

  &__video {
    transform: translate(-50%, -50%);
  }

  .swiper-slide {
    #{$main-class}__button {
      -webkit-transform: translate3d(0, 0, 0);
    }

    &:hover {
      #{$main-class}__button {
        opacity: 1;
      }
    }
  }

  // Prepare elements for animation.
  .js & {
    .swiper-slide {
      opacity: 0;
      transform: translateY(80px);
      transition: opacity calc($at / 2) $te, transform calc($at / 2) $te;
    }
    .swiper-slide-prev,
    .swiper-slide-next {
      transition-delay: calc($at / 4);
    }
  }

  // Animate the prepared elements.
  .js &.animated {
    .swiper-slide {
      opacity: 1;
      transform: none;
    }
  }

  // No animations when reduced motion is active
  @media (prefers-reduced-motion) {
    .swiper-slide {
      opacity: 1 !important;
      transform: none !important;
    }
  }
}
