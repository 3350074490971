.alignnone {
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
}

.aligncenter {
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  display: block;
  margin: 0 auto;
}

.alignleft {
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  float: left;
  max-width: 50%;
  margin: 0 $spacing-nano $spacing-nano 0;
}

.alignright {
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.1);
  float: right;
  max-width: 50%;
  margin: 0 0 $spacing-nano $spacing-nano;
}

.gallery {
  display: flex;
  flex-wrap: wrap;
  margin: $spacing-xxs 0 0 -#{$spacing-nano};

  @media (min-width: theme("screens.md")) {
    margin-left: -#{$spacing-xxs};
  }

  &-item {
    flex: 0 1 100%;
    margin: 0 0 $spacing-xxs 0;
    padding-left: $spacing-nano;
    text-align: center;

    @media (min-width: theme("screens.md")) {
      padding-left: $spacing-xxs;
    }

    img {
      display: inline-block;
    }
  }

  &.gallery-columns-2 .gallery-item {
    flex-basis: 50%;
  }

  &.gallery-columns-3 .gallery-item {
    flex-basis: 33.333333%;
  }

  &.gallery-columns-4 .gallery-item {
    flex-basis: 25%;
  }

  &.gallery-columns-5 .gallery-item {
    flex-basis: 20%;
  }

  &.gallery-columns-6 .gallery-item {
    flex-basis: 16.666667%;
  }

  &.gallery-columns-7 .gallery-item {
    flex-basis: 14.285714%;
  }

  &.gallery-columns-8 .gallery-item {
    flex-basis: 12.5%;
  }

  &.gallery-columns-9 .gallery-item {
    flex-basis: 11.111111%;
  }
}

.pagination {
  border-top: 1px solid #00304d;
  color: #346889;
  font-size: rem(22);
  font-weight: 500;
  margin: 0 0 $spacing-lg 0;
  padding-top: $spacing-nano;
  text-align: right;

  a {
    color: #00304d;

    &:hover {
      @apply text-neutral-medium;
    }
  }

  .page-numbers {
    margin-left: 10px;

    @media (min-width: theme("screens.md")) {
      margin-left: 15px;
    }

    @media (min-width: theme("screens.lg")) {
      margin-left: 20px;
    }

    &:first-child {
      margin-left: 0;
    }

    &.current {
      @apply text-neutral-medium;
      font-weight: 700;
    }
  }
}

.nav-links {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  margin: 0 auto;

  .nav-previous,
  .nav-next {
    margin-bottom: $spacing-nano;

    a {
      border: 1px solid #00304d;
      border-radius: 2em;
      color: #00304d;
      display: inline-block;
      font-size: rem(18);
      line-height: 1.4;
      position: relative;
      padding: 0.6em $spacing-xxs;

      &:hover {
        @apply border-neutral-medium;
      }

      &:before,
      &:after {
        font-family: swiper-icons;
        font-size: rem(18);
        font-variant: normal;
        letter-spacing: 0;
        line-height: 1;
        text-transform: none !important;
      }
    }
  }

  .nav-previous {
    margin-right: auto;

    a {
      padding-left: $spacing-nano;

      &:before {
        content: "prev";
        margin-right: $spacing-xxs;
      }
    }
  }

  .nav-next {
    margin-left: auto;

    a {
      padding-right: $spacing-nano;

      &:after {
        content: "next";
        margin-left: $spacing-xxs;
      }
    }
  }
}

.screen-reader-text {
  border: 0 !important;
  clip: rect(1px 1px 1px 1px);
  /* IE6, IE7 */
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px !important;
  overflow: hidden;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
}

.wp-video,
.mejs-container {
  max-width: 100%;

  video {
    max-width: 100%;
  }
}

.mejs-layer {
  height: 100% !important;
  width: 100% !important;
}
